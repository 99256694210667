import { type FC, type PropsWithChildren, useEffect } from 'react';

import { I18nProvider as GenericI18nProvider } from '@cofenster/web-components';

import { TRANSLATIONS } from './translations';

export const I18nProvider: FC<PropsWithChildren<Record<string, unknown>>> = ({ children }) => {
  const locale = navigator.language.split('-')[0]?.toUpperCase();

  useEffect(() => {
    if (locale) document.documentElement.setAttribute('lang', locale.toLowerCase());
  }, [locale]);

  return (
    <GenericI18nProvider translations={TRANSLATIONS} userLocale={locale} defaultLocale="EN">
      {children}
    </GenericI18nProvider>
  );
};
