import { MAINTENANCE_CLIENT_DSN } from '@cofenster/constants';
import { initSentry } from '@cofenster/sentry/src/client';

const CI_COMMIT_SHA = process.env.CI_COMMIT_SHA;
const STAGE = process.env.STAGE as string;

export const Sentry = initSentry({
  stage: STAGE,
  dsn: MAINTENANCE_CLIENT_DSN,
  release: CI_COMMIT_SHA,
  useReactRouter: true,
});
