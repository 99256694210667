import type { FC, PropsWithChildren } from 'react';

import {
  Button,
  GridContainer,
  GridItem,
  Headline,
  LegalNavigation,
  LogoWide,
  Text,
  styled,
} from '@cofenster/web-components';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.brand.linen,
  minHeight: '100%',
}));

const Content = styled('div')(({ theme }) => ({
  borderRadius: theme.shape['borderRadius-l'],
  padding: theme.spacing(10),
  maxWidth: 600,
}));

const Picture = styled('img')(({ theme }) => ({
  display: 'block',
  height: 648,
  borderRadius: theme.spacing(3),
}));

const PageHeadline = styled(Headline)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  lineHeight: 1.2,
}));

const PageSubline = styled(Text)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const MaintenancePage: FC<PropsWithChildren> = () => (
  <Container>
    <GridContainer justifyContent="space-between" flexDirection="column" alignItems="stretch" flex={1} pl={6} pr={6}>
      <GridItem mt={3} component="header">
        <LogoWide size={82} />
      </GridItem>
      <GridItem component="main">
        <GridContainer justifyContent="center" alignItems="center" spacing={4}>
          <GridItem display={{ xs: 'none', md: 'none', lg: 'flex' }} component="picture">
            <source srcSet="/assets/images/error-page.avif" type="image/avif" />
            <source srcSet="/assets/images/error-page.webp" type="image/webp" />
            <Picture src="/assets/images/error-page.png" alt="" />
          </GridItem>
          <GridItem>
            <Content>
              <PageHeadline variant="h2" component="h1">
                i18n.headline
              </PageHeadline>
              <PageSubline variant="xl" component="p">
                i18n.content
              </PageSubline>
              <Button href="mailto:success@cofenster.com">i18n.global.errorPage.contactLink</Button>
            </Content>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem mt={4} mb={3} component="footer">
        <LegalNavigation />
      </GridItem>
    </GridContainer>
  </Container>
);
