import type { FC } from 'react';

import { Intercom } from '@cofenster/intercom';
import { ThemeProvider, useVersionLog } from '@cofenster/web-components';

import pkg from '../package.json';

import { ErrorBoundary } from './components/ErrorBoundary';
import { MaintenancePage } from './components/MaintenancePage';
import { I18nProvider } from './i18n';

export const App: FC = () => {
  useVersionLog(pkg);

  return (
    <ThemeProvider>
      <I18nProvider>
        <ErrorBoundary>
          <Intercom />
          <MaintenancePage />
        </ErrorBoundary>
      </I18nProvider>
    </ThemeProvider>
  );
};
